import { NextPage } from 'next';
import Head from 'next/head';
import { LandingPage } from '../components/pages/landingPage/LandingPage';
import { LoadingPage } from '../components/pages/loadingPage/LoadingPage';
import { useAuthContext } from '../contexts/AuthContext';
import { useEffect, useState } from 'react';
import router from 'next/router';
import { LoadingPage2 } from '../components/pages/loadingPage/LoadingPage2';
import Script from 'next/script';

const Demo: NextPage = () => {
  const { attemptedSignIn, userData } = useAuthContext();

  return (
    <div>
      <Head>
        <title>SuperCopy</title>
        <meta
          name="description"
          content="Revolutionize the way we talk with one another"
        />
        <link rel="icon" href="/Supercopy_Favicon.png" />
      </Head>
      <Script
        async
        strategy="afterInteractive"
        src="https://www.googletagmanager.com/gtag/js?id=G-B2NQ7H87B8"
      ></Script>
      <Script strategy="afterInteractive" id="gtm">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${
              process.env.GOOGLE_AD_MANAGER_ID || ''
            }',{ 'debug_mode':true });
          `}
      </Script>
      <Script
        async
        src="https://cdn.tolt.io/tolt.js"
        data-tolt="133fbbc2-e079-432c-b3be-77d5deabc9c2"
      />
      ``
      {attemptedSignIn &&
      (!userData?.finishedOnboarding || !userData?.stripe) ? (
        <>
          <main style={{ backgroundColor: 'var(--ea-beige)' }}>
            {/* This is where the redirect is happening -> */}
            <LoadingPage2 />
          </main>
        </>
      ) : (
        <LoadingPage />
      )}
    </div>
  );
};

export default Demo;
