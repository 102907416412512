import { FC, useEffect } from 'react';
import Image from 'next/image';
import styles from './LoadingPage.module.css';
import router from 'next/router';

export const LoadingPage2: FC = () => {

  useEffect(() => {
      router.push('https://www.app.supercopy.ai');
  }, []);
  
  return (
    <div className={styles['container']}>
      <h2>☕ Preparing SuperCopy's coffee with cream and sugar...</h2>
      <span>&nbsp;&nbsp;</span>
      <Image
            src="/demoGifs/animation.gif"
            alt=""
            objectFit="contain"
            height={25}
            width={50}
          />
        <span>&nbsp;&nbsp;</span>
      <h3>If not redirected in 10 seconds, please refresh the page.</h3>
    </div>
  );
};
